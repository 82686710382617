import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  background-color: #e9dfd0;

  @media (max-width: 1023px) {
    padding: 40px 0;
  }

  @media (min-width: 1024px) {
    padding-top: 100px;
    padding-bottom: 82px;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1113px;
  padding: 0 24px;

  @media (max-width: 1023px) {
    overflow-x: hidden;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h4`
  width: 100%;
  margin: 0;
  color: #141f35;
  font-family: 'GreatWestern';
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 32px;
    text-align: center;
  }

  @media (min-width: 640px) {
    font-size: 48px;
  }

  @media (min-width: 1024px) {
    font-size: 32px;
  }
`;

export const Items = styled.div`
  position: relative;
  margin-top: 40px;
  margin-bottom: 124px;

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const StampImg = styled.img`
  position: absolute;

  @media (max-width: 1023px) {
    bottom: -70px;
    right: -55px;
    width: 183px;
  }

  @media (min-width: 1024px) {
    bottom: -150px;
    left: -122px;
  }
`;

export const Item = styled.div`
  position: relative;

  @media (max-width: 1023px) {
    max-width: 640px;
    margin-bottom: 40px;
  }

  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 95px;
  }
`;

export const ItemImg = styled.img`
  @media (max-width: 1023px) {
    margin-bottom: 20px;
  }

  @media (min-width: 1024px) {
    width: calc(50% - 40px);
    object-fit: cover;
  }
`;

export const ItemBody = styled.div`
  @media (min-width: 1024px) {
    width: 50%;
  }
`;

export const ItemTitle = styled.h5`
  margin: 0;
  color: #141f35;
  font-family: 'GreatWestern';
  font-size: 27px;
  line-height: 1;
  letter-spacing: 0.05em;
`;

export const ItemDesc = styled.p`
  margin: 16px 0;
  color: #141f35;

  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 30px;
  }
`;

export const ItemLink = styled.a`
  height: 48px;
  margin-top: 8px;
  padding: 0 16px;
  color: #ce9b61;
  font-family: 'DharmaGothic';
  font-size: 30px;
  line-height: 48px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border: 1px solid #ce9b61;
`;
