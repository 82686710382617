import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PageLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  color: #d1914c;
  font-family: 'GreatWestern';
  letter-spacing: 0.05em;
  line-height: 1;

  &.--active {
    color: #141f35;
  }

  @media (max-width: 1023px) {
    font-size: 18px;
  }

  @media (min-width: 1024px) {
    font-size: 27px;
  }
`;

export const PageList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;

  @media (max-width: 1023px) {
    margin: 0 20px;
  }

  @media (min-width: 1024px) {
    margin: 0 40px;
  }
`;

export const PageItem = styled.li`
  &:not(:last-of-type) {
    @media (max-width: 1023px) {
      margin-right: 15px;
    }

    @media (min-width: 1024px) {
      margin-right: 30px;
    }
  }
`;
