import React from "react";
import { Link } from "gatsby";

import { Root, PageLink, PageList, PageItem } from "./Pagination.style";

const Pagination = () => {
  return (
    <Root>
      <PageLink as={Link} to="/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12.009"
          height="19.337"
          viewBox="0 0 12.009 19.337"
        >
          <path
            d="M2371.125,4597.11l-10.39-9.109,10.39-9.109"
            transform="translate(-2359.607 -4578.333)"
            fill="none"
            stroke="#ce9b61"
            strokeMiterlimit="10"
            strokeWidth="1.488"
          />
        </svg>
      </PageLink>
      <PageList>
        <PageItem>
          <PageLink className="--active" as={Link} to="/">
            1
          </PageLink>
        </PageItem>
        <PageItem>
          <PageLink as={Link} to="/">
            2
          </PageLink>
        </PageItem>
        <PageItem>
          <PageLink as={Link} to="/">
            3
          </PageLink>
        </PageItem>
        <PageItem>
          <PageLink as={Link} to="/">
            4
          </PageLink>
        </PageItem>
      </PageList>
      <PageLink as={Link} to="/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12.009"
          height="19.337"
          viewBox="0 0 12.009 19.337"
        >
          <path
            d="M2726.875,4597.11l10.39-9.109-10.39-9.109"
            transform="translate(-2726.384 -4578.333)"
            fill="none"
            stroke="#ce9b61"
            strokeMiterlimit="10"
            strokeWidth="1.488"
          />
        </svg>
      </PageLink>
    </Root>
  );
};

export default Pagination;
