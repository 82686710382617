import React from "react";

import {
  Root,
  Container,
  Header,
  Title,
  Items,
  StampImg,
  Item,
  ItemImg,
  ItemBody,
  ItemTitle,
  ItemDesc,
  ItemLink,
} from "./MediaPressList.style";

import Pagination from "../Pagination/Pagination";

import Img1Src from "../../images/our-history-item-1.jpg";
import Img2Src from "../../images/our-history-item-2.jpg";
import Img3Src from "../../images/our-history-item-3.jpg";

import StampSrc from "../../images/stamp-beige.svg";

const MediaPressList = () => {
  return (
    <Root>
      <Container>
        <Header>
          <Title>Media & Press</Title>
        </Header>

        <Items>
          <StampImg src={StampSrc} alt="" />
          <Item>
            <ItemImg src={Img1Src} alt="" />
            <ItemBody>
              <ItemTitle>HEADLINE ONE</ItemTitle>
              <ItemDesc>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                in purus vehicula, laoreet nunc quis, varius velit. Suspendisse
                viverra malesuada porta. Integer elementum orci nibh, non
                pharetra elit ultricies vitae. Etiam sodales et eros vitae
                sagittis. Aenean magna est, viverra sed rutrum vitae, tempus
                vitae felis.
              </ItemDesc>
              <ItemLink>Read more</ItemLink>
            </ItemBody>
          </Item>

          <Item>
            <ItemImg src={Img2Src} alt="" />
            <ItemBody>
              <ItemTitle>HEADLINE TWO</ItemTitle>
              <ItemDesc>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                in purus vehicula, laoreet nunc quis, varius velit. Suspendisse
                viverra malesuada porta. Integer elementum orci nibh, non
                pharetra elit ultricies vitae. Etiam sodales et eros vitae
                sagittis. Aenean magna est, viverra sed rutrum vitae, tempus
                vitae felis.
              </ItemDesc>
              <ItemLink>Read more</ItemLink>
            </ItemBody>
          </Item>

          <Item>
            <ItemImg src={Img3Src} alt="" />
            <ItemBody>
              <ItemTitle>HEADLINE THREE</ItemTitle>
              <ItemDesc>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                in purus vehicula, laoreet nunc quis, varius velit. Suspendisse
                viverra malesuada porta. Integer elementum orci nibh, non
                pharetra elit ultricies vitae. Etiam sodales et eros vitae
                sagittis. Aenean magna est, viverra sed rutrum vitae, tempus
                vitae felis.
              </ItemDesc>
              <ItemLink>Read more</ItemLink>
            </ItemBody>
          </Item>

          <Item>
            <ItemImg src={Img1Src} alt="" />
            <ItemBody>
              <ItemTitle>HEADLINE FOUR</ItemTitle>
              <ItemDesc>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                in purus vehicula, laoreet nunc quis, varius velit. Suspendisse
                viverra malesuada porta. Integer elementum orci nibh, non
                pharetra elit ultricies vitae. Etiam sodales et eros vitae
                sagittis. Aenean magna est, viverra sed rutrum vitae, tempus
                vitae felis.
              </ItemDesc>
              <ItemLink>Read more</ItemLink>
            </ItemBody>
          </Item>
        </Items>

        <Pagination />
      </Container>
    </Root>
  );
};

export default MediaPressList;
